<template>
  <v-container>
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <h1 class="display-1 mb-6">User Feedback Report</h1>

    <v-card>
      <v-card-text>
        <v-select
          v-if="this.$store.state.isAdmin"
          required
          :items="clients"
          v-model="client"
          item-text="name"
          :loading="loading"
          label="Organization"
        >
          <!-- <template v-slot:selection="{ name }">
            <v-img
              :src="require('@/assets/clients/' + name + '_logo.png')"
              :alt="name"
              max-width="32"
              class="mr-2 ma-1"
            ></v-img> {{ name }}
          </template> -->
          <template v-slot:item="slotProps">
            <v-img
              :src="require('@/assets/clients/' + slotProps.item.name + '_logo.png')"
              :alt="slotProps.item.name"
              max-width="32"
              class="mr-2"
            ></v-img> {{ slotProps.item.name }}
          </template>
        </v-select>

        <v-btn-toggle
          v-model="toggle_exclusive"
          class="mb-6"
        >
          <v-btn v-on:click="setToday">
            Today
          </v-btn>
          <v-btn v-on:click="setLastWeek">
            Last Week
          </v-btn>
          <v-btn v-on:click="setLastMonth">
            Last Month
          </v-btn>
        </v-btn-toggle>

        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Activity Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn v-on:click="exportPDF" :loading="pdfLoading" color="primary" class="ma-2">
            <v-icon left>
              mdi-file-pdf-box
            </v-icon>
            Download PDF
          </v-btn>
          <v-btn v-on:click="exportCSV" :loading="csvLoading" color="secondary" class="ma-2">
            <v-icon left>
                mdi-file
            </v-icon>
            Download CSV
          </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import getClients from '@/services/getClients';
// import { keySort } from '@/services/sort';
import clientData from '@/services/clients.json';
import getActivityExport from '@/services/getActivityExport';

export default {
  name: 'UserFeedback',
  metaInfo: {
    title: 'User Feedback Report',
  },
  data: () => ({
    date: ['2021-03-05'],
    clients: clientData,
    client: 'Ascot',
    loading: false,
    error: false,
    message: '',
    menu: false,
    modal: false,
    menu2: false,
    pdfLoading: false,
    csvLoading: false,
    toggle_exclusive: undefined,
    items: [
      {
        text: 'AppHub',
        exact: true,
        to: '/',
      },
      {
        text: 'Reports',
        exact: true,
        to: '/reports',
      },
      {
        text: 'User Feedback Report',
        exact: true,
      },
    ],
  }),
  computed: {
    dateRangeText() {
      return this.date.join(' ~ ');
    },
    client() {
      return this.$store.state.client;
    },
  },
  created() {
    const today = new Date();
    const dateStr = `${String(today.getFullYear())}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    this.date = [dateStr];
  },
  methods: {
/* eslint-disable */
    // getClients() {
      // this.loading = true;
      // getClients()
      //   .then((results) => {
      //     results.data.sort(keySort('name')).forEach(item => {
      //       this.clients.push(item.name);
      //     });
      //     this.loading = false;
      //   })
      //   .catch((error) => {
      //     console.log(error); // eslint-disable-line
      //     this.error = true;
      //     this.message = error.message;
      //   });
    // },
    setToday() {
      const today = new Date();
      const dateStr = `${String(today.getFullYear())}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      this.date = [dateStr];
    },
    setLastWeek() {
      const today = new Date();
      const startingSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
      const endingSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      const startStr = `${String(startingSunday.getFullYear())}-${String(startingSunday.getMonth() + 1).padStart(2, '0')}-${String(startingSunday.getDate()).padStart(2, '0')}`;
      const endStr = `${String(endingSunday.getFullYear())}-${String(endingSunday.getMonth() + 1).padStart(2, '0')}-${String(endingSunday.getDate()).padStart(2, '0')}`;

      this.date = [startStr, endStr];
    },
    setLastMonth() {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      const startStr = `${String(firstDay.getFullYear())}-${String(firstDay.getMonth() + 1).padStart(2, '0')}-${String(firstDay.getDate()).padStart(2, '0')}`;
      const endStr = `${String(lastDay.getFullYear())}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;

      this.date = [startStr, endStr];
    },
    exportPDF() {
      const dates = this.date;
      // Make sure dates selected are properly sorted
      let start = null;
      let end = null;
      if (dates[0] == dates[1]) {
        start = dates[0];
      } else if (dates[0] > dates[1]) {
        start = dates[1];
        end = dates[0];
      } else {
        start = dates[0];
        end = dates[1];
      }

      // Format date range and url for PDF export
      let dateRange = null;
      if (end) {
        dateRange = `${start.replace(/-+/g, '')}to${end.replace(/-+/g, '')}`;
      } else {
        dateRange = start.replace(/-+/g, '');
      }

      this.pdfLoading = true;

      getActivityExport(dateRange, this.client, 'pdf', 'user_feedback')
        .then((response) => {
          const linkSource = `data:application/pdf;base64,${response}`;
          const downloadLink = document.createElement("a");
          const fileName = `${dateRange}-user-feedback-report.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

          this.pdfLoading = false;
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        this.error = true;
        this.message = error.message;
        this.pdfLoading = false;
      });
    },
    exportCSV() {
      const dates = this.date;
      // Make sure dates selected are properly sorted
      let start = null;
      let end = null;
      if (dates[0] == dates[1]) {
        start = dates[0];
      } else if (dates[0] > dates[1]) {
        start = dates[1];
        end = dates[0];
      } else {
        start = dates[0];
        end = dates[1];
      }

      // Format date range and url for PDF export
      let dateRange = null;
      if (end) {
        dateRange = `${start.replace(/-+/g, '')}to${end.replace(/-+/g, '')}`;
      } else {
        dateRange = start.replace(/-+/g, '');
      }

      this.csvLoading = true;

      getActivityExport(dateRange, this.client, 'csv', 'user_feedback')
        .then((response) => {
          var binaryData = [];
          binaryData.push(response);
          var link = document.createElement("a");
          var url = URL.createObjectURL(new Blob(binaryData, {type: "application/csv"}));
          link.setAttribute("href", url);
          link.setAttribute("download", `${dateRange}-user-feedback-report.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // const linkSource = `data:text/csv;${response}`;
          // const downloadLink = document.createElement("a");
          // const fileName = 'user-feedback-report.csv';
          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.click();

          this.csvLoading = false;
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        this.error = true;
        this.message = error.message;
        this.csvLoading = false;
      });
    },
    /* eslint-enable */
  },
  // mounted() {
  //   this.getClients();
  // },
};
</script>
